import {Link} from "gatsby";
import React, {useEffect} from 'react';
import BlackLogo from '../../src/images/S7-logo.svg';
import WhiteLogo from '../../src/images/s7-logo-white.svg';
import PurpleLogo from '../../src/images/s7-logo-pur.svg';

import "../styles/header.scss"

const activeStyles = {
    fontWeight: 'bold'
}

const Header = (props) => {
    useEffect(() => {
        if (window) {
            let page = window.location.href;
            let logo = document.getElementById('logoLink');
            let navBar = document.getElementById('header');
            if (page.indexOf("/career/internship") > -1) {
                logo.classList.add("white");
                navBar.classList.add("white");
            } else if (page.indexOf("/about") > -1) {
                logo.classList.add("purple");
            } else {
                logo.classList.add("black");
            }

            let header = document.getElementById('header');

            window.onscroll = function () {
                if (window.pageYOffset > 1) {
                    header.classList.add("active");
                } else {
                    header.classList.remove("active");
                }

                let image = document.querySelector(".internship-banner--front");
                let element = document.querySelector(".rocket");
                if (element) {
                    let desiredPosition = 30;
                    if (window.pageYOffset >= desiredPosition) {
                        image.style.cssText += "opacity: 1;";
                        element.style.cssText += "bottom: 50%;";
                    }
                    else {
                        element.style.cssText += "bottom: 30%;";
                    }
                }

                let bgImage = document.getElementById('about-main-logo');
                if (bgImage !== null) {
                    if (window.pageYOffset > 200) {
                        bgImage.classList.add("opacity");
                    } else {
                        bgImage.classList.remove("opacity");
                    }
                }
            };

        }
    }, [])
        return (
            <div className="header" id="header">
                <div className="container">
                    <nav className="row justify-content-center main-navigation" role="navigation">

                        <Link id="logoLink" className="navbar-brand" to="/">
                            <img className="black" src={BlackLogo} alt={""}/>
                            <img className="white" src={WhiteLogo} alt={""}/>
                            <img className="purple" src={PurpleLogo} alt={""}/>
                        </Link>

                        <ul className="col-8 main-navigation offset-2">
                            <li className="main-navigation--item"><Link to="/about" activeStyle={activeStyles}>About
                                us</Link></li>
                            <li className="main-navigation--item"><Link to="/technology"
                                                                        activeStyle={activeStyles}>Technology</Link></li>
                            <li className="main-navigation--item"><Link to="/career"
                                                                        activeStyle={activeStyles}>Career</Link></li>
                            <li className="main-navigation--item"><Link to="/contact"
                                                                        activeStyle={activeStyles}>Contact</Link></li>
                        </ul>
                    </nav>
                </div>
                {/*Mobile menu*/}
                <div className="burger-menu">
                    <div className="burger row">
                        <div className="col-12 text-center">
                            {/*<Link className="navbar-brand" to="/"></Link>*/}
                            <Link id="logoLink" className="navbar-brand" to="/">
                                <img className="black" src={BlackLogo} alt={""}/>
                            </Link>
                        </div>
                    </div>
                    <nav role="navigation">
                        <div id="menuToggle">
                            <input type="checkbox"/>
                            <span></span>
                            <span></span>
                            <span></span>
                            <ul className="mob-navigation" id="mob-nav">
                                <li className="main-navigation--item"><Link to="/about" activeStyle={activeStyles}>About
                                    us</Link></li>
                                <li className="main-navigation--item"><Link to="/technology"
                                                                            activeStyle={activeStyles}>Technology</Link>
                                </li>
                                <li className="main-navigation--item"><Link to="/career"
                                                                            activeStyle={activeStyles}>Career</Link></li>
                                <li className="main-navigation--item"><Link to="/contact"
                                                                            activeStyle={activeStyles}>Contact</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            )

}

export default Header
