import { Link } from "gatsby"
import React from "react"
import FooterBg from "../images/footer_bg.png";

import "../styles/footer.scss"

const Footer = () => (
    <div className="footer"style={{ backgroundImage: `url(${FooterBg})` }}>
        <div className="row footer-inner">
            <div className="offset-sm-1 col-lg-5 col-11 footer-inner--left">
                <div className='title'><Link to="/contact">Say hello.</Link></div>

                <p><label>Email:</label><a href="mailto:info@s7codedesign.com"> info@s7codedesign.com</a></p>
                <a className="phone" href="tel:+381 65 21 52 004">Tel: +381 65 21 52 004</a>
                <address>
                <label>Address:</label><br/>
                Narodnog Fronta 21<br/>Novi Sad<br/>Serbia
                </address>

                <Link className="maps" to="/maps">View in maps</Link>

            </div>
            <div className="col-lg-5 col-sm-10 footer-inner--right">
                <br/>
                <br/>
                <br/>
                <div className="row justify-content-end footer-soc-section">
                    <ul className="col-12 social-menu pl-0 pr-0">
                        <label>Follow us:</label>
                        {/*<li><a className="icon-instagram-filled" href="https://www.instagram.com/s7codedesign/" target='_blank' rel="noopener noreferrer"> </a></li>*/}
                        {/*<li><a className="icon-linkedin-rect" href="https://www.linkedin.com/company/s7-design" target='_blank' rel="noopener noreferrer"> </a></li>*/}
                        {/*<li><a className="icon-facebook-rect" href="https://www.facebook.com/s7codedesign/" target='_blank' rel="noopener noreferrer"> </a></li>*/}
                        <li><a href="https://www.instagram.com/s7codedesign/" target='_blank' rel="noopener noreferrer">instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/s7-design" target='_blank' rel="noopener noreferrer">linkedIn</a></li>
                        <li><a href="https://www.facebook.com/s7codedesign/" target='_blank' rel="noopener noreferrer">facebook</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
)

export default Footer