import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import {Helmet} from "react-helmet"
import Header from "./header"
import Footer from "../components/footer"
import "../fontello/css/fontello.css"
import favicon from '../images/icon.png'

const Layout = ({ children }) => {
    const metaData = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
    );

    return(
        <div>
            <Helmet defer={false}>
                <meta charSet="utf-8" />
                <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"/>
                <link href="https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,900&amp;subset=latin-ext" rel="stylesheet"/>
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.2/css/all.css" integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr" crossorigin="anonymous"/>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.1/tiny-slider.css"/>
                <link rel="icon" href={favicon} type="image/png" />
            </Helmet>
            <Header siteTitle={metaData.site.siteMetadata.title} />
            {children}
            <Footer/>
        </div>
    )
};


Layout.propTypes = {
    children: PropTypes.any,
};

export default Layout

export const fluidImage = graphql`
        fragment fluidImage on File {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        `;

//for json file
// const trans = useStaticQuery(
//         graphql`
//            query
//             HomepageSrQuery{
//                 allHomepageSrJson{
//                     edges{
//                         node{
//                             label
//                         }
//                     }
//
//                 }
//             }
//         `
// );

